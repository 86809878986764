import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';

const ViewSwitcherLanguage = (props) => {
    const {
        t, title, handleClick, dataLang, loadDataLang,
    } = props;
    const buttonRef = useRef();

    const styleTitle = { fontSize: 12, textTransform: 'uppercase' };
    const styleButton = { fontFamily: 'Montserrat', padding: '0px', fontSize: title ? '12px' : '1em' };
    const listDataLanguage = [];

    if (dataLang !== undefined) {
        dataLang.map((item) => {
            listDataLanguage.push(item);
            return item;
        });
    }

    /**
     * rendering
     */
    return (
        <div>
            {loadDataLang && (
                <div>
                    <Skeleton style={{ padding: 0 }} variant="rect" width={100} height={10} />
                    <Skeleton style={{ display: 'inline-block', padding: 0 }} variant="rect" width={100} height={10} />
                </div>
            )}
            {!loadDataLang && dataLang !== undefined && (
                <div>
                    {/* [CURRENCY] TITLE */}
                    {title && (
                        <div>
                            <strong style={styleTitle}>{title}</strong>
                        </div>
                    )}

                    {/* [CURRENCY] BUTTON */}
                    <Button ref={buttonRef} onClick={handleClick} style={styleButton}>
                        {t('common:setting:store')}
                        :&nbsp;
                        <strong>{t('Indonesia')}</strong>
                    </Button>

                </div>
            )}
        </div>
    );
};

export default ViewSwitcherLanguage;
